import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Avatar, Button, Col, Divider, Form, InputNumber, Row, Select, Space, Spin } from 'antd';
import { AppstoreOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import parkService from '../../../services/config/park.service';
import { Link } from 'react-router-dom';

const AddTicketAddOnModal = ({ form, ticket_type, modal_visible, add_on_setups }) => {
    const [state, setState] = useState({
        loading : false,
        add_ons : [],
    });

    const fetchAddOn = async () => {
        const addOnResponse = await parkService?.addOnList();
        const { add_ons } = addOnResponse;
        return add_ons;
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true, }));
        const add_ons = await fetchAddOn();

        setState(state => ({...state, loading : false, add_ons, }));
    }

    useEffect(() => {
        initialize();
    }, [ticket_type?.ticket_type_id, modal_visible])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div>
                    <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Add On Detail</span>} textAlign={'start'} /></Divider>
                    <div>
                        <Form.Item label={"Add On"} name={"add_on_id"}>
                            <Select
                            showSearch
                            optionFilterProp="label2"
                            options={state?.add_ons?.map(x => ({ label : (
                                <>
                                    <div>
                                        <Space wrap align='center'>
                                            {
                                                x?.image && (
                                                    <>
                                                        <div>
                                                            <Avatar shape='square' src={x?.image} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            
                                            <div>
                                                <span>{x?.name}</span>
                                            </div>
                                        </Space>
                                    </div>
                                </>
                            ),
                            disabled : add_on_setups?.find(add_on_setup => add_on_setup?.add_on_id === x?.add_on_id),
                            label2 : x?.name,
                            value : x?.add_on_id,
                            // min_age : x?.min_age,
                            // max_age : x?.max_age,
                            // descriptions : x?.descriptions,
                            }))}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <div>
                                        <Divider style={{ marginBlock : 8, }} />
                                        <div style={{ padding : 8, }}>
                                            <Link to="/config/add_on">
                                                <Button icon={<PlusCircleOutlined />}>Add On</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            )}
                            optionLabelProp='label2'
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label="Ticket Type">
                            <Space>
                                <div>
                                    <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={ticket_type?.ParkApplicableTicketType?.image} />
                                </div>
                                <div>
                                    <span>{ticket_type?.name}</span>
                                </div>
                            </Space>
                        </Form.Item>
                    </div>
                    <div>
                        <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Purchase Detail</span>} textAlign={'start'} /></Divider>
                        <Row gutter={12}>
                            <Col xs={24} md={12} span={12}>
                                <Form.Item label={"Price"} name={"price"}>
                                    <InputNumber min={0} addonBefore={"MYR"} style={{ width : '100%', }} precision={2} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} span={12}>
                                <Form.Item label={"Advance Day"} name={"advance_day"}>
                                    <InputNumber min={0} style={{ width : '100%', }} precision={0} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label={"Date Range"} name={"date_range"}>
                                    <InputNumber style={{ width : '100%', }} precision={0} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default AddTicketAddOnModal;